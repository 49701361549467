import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import moment from "moment";
import useStyles from "./styles";
import { useDispatch } from "react-redux";
import {
  deletePost,
  likePost,
  dislikePost,
  watchPost,
  bookMarkPost,
} from "../../../actions/posts";
import { Likes, DisLikes, Watches, BookMarks } from "./Reactions.js";
const Post = ({ post, setCurrentId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("profile"));

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.media}
        image={post.imageUrl}
        title={post.title}
      />
      <div className={classes.overlay}>
        <Typography variant="h6">{post.name}</Typography>
        <Typography variant="body2">
          {moment(post.createdAt).fromNow()}
        </Typography>
      </div>
      <div className={classes.overlay2}>
        {(user?.result?.googleId === post?.creator ||
          user?.result?._id === post?.creator) && (
            <Button
              style={{ color: "white" }}
              size="small"
              onClick={() => setCurrentId(post._id)}
            >
              <MoreHorizIcon fontSize="default" />
            </Button>
          )}
      </div>
      <div className={classes.details}>
        <Typography variant="body2" color="textSecondary">
          {post.tags.map((tag) => `#${tag} `)}
        </Typography>
      </div>
      <Typography className={classes.title} variant="h5" gutterBottom>
        {post.title}
      </Typography>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {post.synopsis}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          className={classes.buttonRoot}
          size="small"
          color="primary"
          disabled={!user?.result}
          onClick={() => dispatch(likePost(post._id))}
        >
          <Likes post={post} user={user} />
        </Button>
        <Button
          className={classes.buttonRoot}
          size="small"
          color="primary"
          disabled={!user?.result}
          onClick={() => dispatch(dislikePost(post._id))}
        >
          <DisLikes post={post} user={user} />
        </Button>

        <Button
          className={classes.buttonRoot}
          size="small"
          color="primary"
          disabled={!user?.result}
          onClick={() => dispatch(watchPost(post._id))}
        >
          <Watches post={post} user={user} />
        </Button>

        <Button
          className={classes.buttonRoot}
          size="small"
          color="primary"
          disabled={!user?.result}
          onClick={() => dispatch(bookMarkPost(post._id))}
        >
          <BookMarks post={post} user={user} />
        </Button>

        {(user?.result?.googleId === post?.creator ||
          user?.result?._id === post?.creator) && (
            <Button
              className={classes.buttonRoot}
              size="small"
              color="primary"
              onClick={() => dispatch(deletePost(post._id))}
            >
              <DeleteIcon fontSize="small" />
            </Button>
          )}
      </CardActions>
    </Card>
  );
};

export default Post;